﻿#viewer-wrapper {
	background-color: #000;
	display: flex;
	flex-grow: 1;
	position: relative;
}

#matterport-viewer, #streetview-viewer, #olio-viewer, #everyscape-viewer, #iguide-viewer, #video-viewer, #video-viewer .container-layer {
	display: block;
	border: none;
	height: 100%;
	width: 100%;
}

#video-viewer .vjs-big-play-button {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

#video-viewer .vjs-loading-spinner {
	display: none !important;
}

#video-viewer video {
	visibility: visible;
}

.close-iguide-floorplan-button {
	bottom: 10px;
	cursor: pointer;
	position: absolute;
	right: 20px;
}

.ESViewerObject {
	height: 100% !important;
	width: 100% !important;
}
