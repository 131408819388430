﻿// Header menu buttons, icons, etc, and the mobile nav (hamburger dropdown).
@use 'mixins.scss' as m;

.mobileMenuRoundButton {
	background-color: var(--brandMenuColor);
	border-radius: 50%;
	border-width: 0;
	color: var(--brandMenuPrimaryText);
	cursor: pointer;
	padding: 0;

	&.inverseColors {
		background-color: var(--brandMenuPrimaryText);
		color: var(--brandMenuColor);
	}

	&.disabled {
		cursor: none;
		opacity: .2;
		pointer-events: none;
	}
}

#MobileCallBtn {
	background-color: m.$infinityy-teal;

	&.connected {
		background-color: m.$infinityy-red;
	}
}

#MobileMenuHolder {
	background-color: var(--infinityyNavy);
	color: var(--infinityyPrimaryText);
	display: none;
	grid-area: viewerAndBottomDrawer;
	max-height: 100%;
	overflow: auto;
	padding: 10px;
	width: 100%;
}

#MobileMenuContent {
	background-color: #1d2d57;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	padding: 10px;
}

#MobileAvatarAndName {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 10px 20px 10px;
}

#MobileNav {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	padding-bottom: 20px;
}



#MobileInfinityyLogo {
	height: 24px;
	width: 100%;
	background-size: contain;
	background-position: center center;
	background-image: url(../images/Infinityy-Logo-White.svg);
	background-repeat: no-repeat;
	margin-top: 10px;
	margin-bottom: 10px;
}

#MobileAvatar {
	background-image: url("../images/account_btn.svg");
	background-color: transparent;
	border-radius: 0;
	height: 72px;
	width: 72px;
	cursor: pointer;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-width: 0;
	border-radius: 50%;
	padding: 0;
	margin-bottom: 10px;
}
