﻿// Common paddings, spacings, flex rows, buttons, etc.

.padding-10px {
	padding: 10px;
}

.padding-20px {
	padding: 20px;
}

.marginBottom20 {
	margin-bottom: 20px;
}

.hideIfNotSelected {
	display: none;

	&.selected {
		display: block;
	}
}

.oneLineText {
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-break: break-word;
}

.flex-row {
	display: flex;
	justify-content: center;
}

.twoItemFlex {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& > *:not(:last-child) {
		margin-right: 10px;
	}

	&.oneLine {
		align-items: flex-start;
	}

	&.endAlign {
		align-items: flex-end;
	}

	&.alignStretch {
		align-items: stretch;
	}

	&.alignStretch > *:not(:last-child) {
		margin-right: 0;
	}

	&.justifyStart {
		justify-content: flex-start;
	}

	&.justifyEnd {
		justify-content: flex-end;
	}

	&.justifyStretch {
		justify-content: stretch;
	}
}

.infinityyTextArea {
	background-color: var(--brandContentColor);
	border-radius: 20px;
	border-width: 0;
	box-shadow: 0 1px 0 rgba(0,0,0,.2) inset;
	color: var(--brandContentPrimaryText);
	font-size: 18px;
	margin-bottom: 10px;
	max-width: 100%;
	padding: 20px;
	transition: box-shadow linear .2s;
	width: 100%;
}

// Buttons (non-modal)

.flexButtonStructure {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 10px;
}

.infinityyButton {
	border-width: 0;
	border-radius: 50px;
	text-align: center;
	padding: 10px 20px 10px 20px;
	white-space: nowrap;
	font-size:14px;
}

.fullWidthButton {
	border-radius: 50px;
	border-width: 0;
	cursor: pointer;
	min-height: 50px;
	padding-left: 20px;
	padding-right: 20px;
	white-space: nowrap;
	width: 100%;
}

.linkButton {
	background-color: transparent;
	border-width: 0;
	color: var(--brandCTAButton);
}

.roundButton {
	border-radius: 50%;
	flex-shrink: 0;
	pointer-events: all;
	border-width: 0;
	border-color: transparent;
	border-style: solid;
	background-clip: padding-box;
	box-shadow: 0 2px 5px rgba(0,0,0,0.4);
	padding: 0;

	& .flexButtonStructure {
		padding: 8px;
	}

	& .material-icons {
		font-size: 18px;
		line-height: 1;
	}
}

.cancelButtonColors {
	background-color: var(--infinityyLightGrey);
	color: black;
}

.button-flex-group {
	display: flex;
	justify-content: space-around;
}

button.close {
	-webkit-appearance: none;
	background: 0 0;
	border: 0;
	cursor: pointer;
	padding: 0;
}

.close {
	color: #000;
	filter: alpha(opacity=20);
	float: right;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	opacity: .2;
	text-shadow: 0 1px 0 #fff;
}

.formButtonSection {
	padding-top: 20px;
}

.accountFormContainer .form-horizontal .control-label, .infinityyLabel {
	font-weight: 700;
	margin-bottom: 4px;
	margin-left: 20px;
}

.inputAndButton {
	align-items: stretch;
	display: flex;
	justify-content: stretch;

	& > input {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		margin-bottom: 0;
		min-width: 0;
	}

	& > button {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
}
