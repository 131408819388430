﻿@mixin animate($animation,$duration,$times,$method) {
	animation: $animation $duration $times $method;
}

@mixin keyframes($name) {
	@keyframes #{$name} {
		@content;
	}
}

.hal9000-spinner {
	@include animate(spin, 7s, infinite, linear);
	height: 32px;
	position: relative;
	width: 30px;

	@include keyframes(spin) {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	.layer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		font-family: "Open Sans", sans-serif;
		font-weight: 400;
		font-style: normal;
		font-size: 12px;
		letter-spacing: normal;
		color: #333333;
		text-align: center;
		line-height: normal;
		text-transform: none;
		transition: all 1s ease-in-out;

		.inner {
			width: 32px;
			height: 32px;
			border-radius: 100%;
			border: 2px solid rgba(170, 109, 247, 0.7);
			border-right: none;
			border-top: none;
			background-clip: padding-box;
			box-shadow: inset 0px 0px 10px rgba(51, 51, 51, 0.15);
			transition: all 1s ease-in-out;
		}
	}

	&.working {
		@include animate(spin, 4s, infinite, linear);

		.layer .inner {
			@include animate(spin, 2s, infinite, linear);
			border: 4px solid rgba(170, 109, 247, 0.7);
			border-right: none;
			border-top: none;
			width: 34px;
			height: 24px;
		}
	}

	.layer:nth-child(1) {
		transform: rotate(70deg);

		.inner {
			@include animate(spin, 4s, infinite, linear);
		}
	}

	.layer:nth-child(2) {
		transform: rotate(140deg);

		.inner {
			@include animate(spin, 4s, infinite, linear);
		}
	}

	.layer:nth-child(3) {
		transform: rotate(210deg);

		.inner {
			@include animate(spin, 4s, infinite, linear);
		}
	}

	&.working .layer:nth-child(1) {
		transform: rotate(0deg);
	}

	&.working .layer:nth-child(2) {
		transform: rotate(72deg);
	}

	&.working .layer:nth-child(3) {
		transform: rotate(144deg);
	}

	&.working .layer:nth-child(4) {
		transform: rotate(216deg);
	}

	&.working .layer:nth-child(5) {
		transform: rotate(288deg);
	}
}
