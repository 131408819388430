﻿// conference call related modals.
@use 'mixins.scss' as m;

#ModalHolderBehindLoader {
	justify-content: center;
	overflow: auto;
	width: 100%;
	z-index: 20;
}

#ModalWeNeedPermissions, #ModalShowWhileChoosingPermissions, #ModalAudioPreferences {
	.modal-header {
		padding: 0px;
	}
	.modal-dialog {
		margin-top: 150px !important;
		padding-bottom: 100px;
	}

	.modalTitle, .modalSubtitle {
		background-color: inherit;
		padding-bottom: 0px;
		margin-top: 0px;
	}

	.modalTitle {
		@include m.boldFont(16px);
	}

	.modalContent {
		padding: 0px;
	}

	.modal-footer {
		display: flex;
		padding: 10px 20px;
	}
}

#ModalShowAudioAndVideoInputs {
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;

	.deviceList {
		box-shadow: none;
	}
}

.devicesPreview {
	flex-shrink: 0;
	display: flex;
	height: 180px;
	width: 100%;

	& .devicePreview {
		height: 100%;
		flex: 1 1 auto;
	}
}

.deviceButtons {
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: flex-start;
	padding: 0 10px 10px 10px;
	position: absolute;
	width: 100%;
	z-index: 100;

	& > *:first-child {
		margin-right: 10px;
	}
}

.roundButton.voipButton {
	background-color: var(--brandHeaderPrimaryText);
	color: var(--brandHeaderColor);
}

#VideoDevicePreview {
	position: relative;

	& video {
		position: relative;
		height: 100%;
		width: 100%;
		border-radius: 0px;
		object-fit: cover;
		transform: scaleX(-1);
		pointer-events: none;
	}

	& .muted-message {
		background-color: rgba(0,0,0,.8);
		color: #fff;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		text-align: center;
		z-index: 100;
		padding: 20px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.deviceHolderHolder {
	padding: 0 20px;
}

.deviceListHolder {
	margin-top: 10px;
}

.deviceList {
	border-radius: 20px;
	margin-bottom: 10px;
	overflow: hidden;
	box-shadow: 0 10px 20px rgba(0,0,0,0.2);

}

.deviceItem {
	background-color: var(--brandMenuColor);
	border-radius: 50px;
	cursor: pointer;
	color: var(--brandMenuPrimaryText);
	display: none;
	font-size: 14px;
	overflow: hidden;
	padding: 10px;
	text-align: center;
	text-overflow: ellipsis;

	&.active {
		align-items: center;
		background-color: var(--brandContentColor);
		border-radius: 20px;
		color: var(--brandContentPrimaryText);
		display: flex;
		justify-content: space-between;

		& .material-icons {
			display: block;
		}

		&:hover {
			background-color: var(--brandContentColor);
			color: var(--brandContentPrimaryText);
		}
	}
}

#AudioDeviceListHolder {
	position: relative;
}

#AudioDeviceListHolder.expanded .deviceItem, #VideoDevicesList.expanded .deviceItem {
	display: flex;
	justify-content: center;
}

#MobileCallSettingsIcon {
	color: white;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	padding: 5px;
	border-radius: 50%;
	font-size: 12px;
	position: absolute;
	bottom: 5px;
	left: 5px;
}
