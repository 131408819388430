﻿.ai-suggested-prompts {
	display: block;
}

.ai-prompt-suggestion {
	background: var(--brandMenuColor);
	border-color: var(--brandCTAButton);
	border-radius: 8px;
	color: var(--brandMenuPrimaryText);
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	font-style: italic;
	font-weight: 400;
	margin-bottom: 8px;
	margin-top: 8px;
	padding: 8px 8px 8px 12px;
	width: 100%;

	&.fadeIn {
		animation: fadeIn 0.5s ease-in-out forwards;

		&:nth-child(2) {
			animation-delay: 0.5s;
		}

		&:nth-child(3) {
			animation-delay: 1.0s;
		}
	}
}

@mixin createSlide($name, $start-y: 0px, $end-y: 0px) {
	@keyframes #{$name} {
		from {
			transform: translateY($start-y);
		}

		to {
			transform: translateY($end-y);
		}
	}
}

@mixin createSlideWithBounce($name, $start-y: 0px, $end-y: -60px) {
	$bounce-dist: 10px; // Adjust the bounce distance here
	@keyframes #{$name} {
		0% {
			transform: translateY($start-y);
		}

		70% {
			transform: translateY($end-y - $bounce-dist); // Move past the final position for the bounce
		}

		80% {
			transform: translateY($end-y + calc($bounce-dist / 2)); // Bounce back
		}

		90% {
			transform: translateY($end-y - calc($bounce-dist / 2)); // Forward again
		}

		100% {
			transform: translateY($end-y); // Settle into the final position
		}
	}
}

@include createSlide(slideUp1, 0px, -60px);
@include createSlide(slideUp2, -60px, -120px);
@include createSlide(slideUp3, -120px, -170px);
@include createSlideWithBounce(slideDown1, 0px, 87px);
@include createSlideWithBounce(slideDown2, 87px, 195px);

@for $i from 1 through 3 {
	.animate-up#{$i} {
		animation: slideUp#{$i} 0.6s ease forwards;
	}

	.animate-down#{$i} {
		animation: slideDown#{$i} 0.3s ease forwards;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.33);
	}

	80%, 100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.8);
	}
}

@keyframes flipImage {
	from {
		transform: perspective(500px) rotateX(90deg);
	}

	to {
		transform: perspective(500px) rotateX(0deg);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
