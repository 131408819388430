﻿$headerMenuHeight: 60px;
$footerHeightReserved: 100px;
$sectionBackgroundColor: #fff;

body {
	background-color: var(--brandContentColor);
	bottom: 0;
	color: var(--brandContentPrimaryText);
	display: flex;
	flex-direction: column;
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	font-weight: 400;
	height: 100%;
	line-height: 1.42857143;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: fixed;
	width: 100vw;
}

#MainBody {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	display: flex;
	flex: 1 1 auto;
	overflow: hidden;
	width: 100vw;
	/* This is attempting to stop the rubberband bounce effect on ios. */
	overscroll-behavior: none;
}

#MainBodyContainer {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: sticky;
	top: 0;
	width: 100%;
	z-index: 19;
}

#MobileMenuAndPips {
	display: block;
	grid-area: mobileMenuAndPips;
	background: var(--brandHeaderColor);
	position: sticky;
	top: 0;
	z-index: 15;
}

#MobileHeaderMenu {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	padding: 10px;
	position: sticky;
	top: 0;
	z-index: 22;
}

#TopOfViewerPips {
	display: block;
	height: Min(25vw, 96px);
	pointer-events: none;
	width: 100%;
	z-index: 30;
}

#TopOfViewerPips:has(~ div:not([style="display: none;"])) {
	display: none!important;
}

#ViewerAndBottomDrawer {
	display: flex;
	flex-grow: 1;
	position: relative;
}

#MainViewerContainer {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	margin: 0;
	min-height: 0;
	top: $headerMenuHeight;
	width: 100%;
	z-index: 20;
}

#ViewersAndUI {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	min-height: 0;
	overflow: auto;
}

#ViewersAndFloatingUI {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	flex: 1 1 auto;
	min-height: 0;
}

#BottomDrawer {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	grid-area: bottomDrawer;
	min-width: 0;
}

#LoadingAnimation {
	align-items: center;
	background-color: var(--brandContentColor);
	background-image: url('../images/Infinityy-ConnectionHeader-brighter.png');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	display: flex;
	flex-direction: column;
	font-size: 24px;
	height: 100%;
	justify-content: center;
	padding: 50px;
	position: fixed;
	text-align: center;
	width: 100%;
	z-index: 21;
}

.loadingAnimationSpinnerHolder {
	align-items: center;
	background-color: rgba(0,0,0,.5);
	border: 0px solid white;
	border-radius: 300px;
	display: flex;
	flex-direction: column;
	height: 300px;
	justify-content: center;
	width: 300px;
}

.loadingAnimationSpinner {
	background-image: url('../images/infinityy-loading4.gif');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 80px;
	margin-bottom: 20px;
	margin-top: 40px;
	width: 80px;
}

#MobilePipStatus {
	height: 60px;
	position: absolute;
	width: 100%;
	z-index: 110;
}
#StatusBarMessage {
	color: var(--brandViewerButtonText);
	font-size: 14px;
	line-height:40px;
	padding-left:10px;
	width: 100%;
}

#StatusBarMessageAndAction {
	align-items: center;
	background-color: var(--brandHeaderColor);
	color: var(--brandHeaderPrimaryText);
	justify-content: space-between;
	min-width: 0;
	width: 100%;
}

.stopGuideFollow {
	align-items: center;
	background-color: white;
	border: solid 0 white;
	border-radius: 20px;
	color: #676767;
	cursor: pointer;
	flex-shrink: 0;
	font-size: 14px;
	margin-left: 5px;
	min-width: unset;
	padding: 10px 15px 10px 15px;
	pointer-events: all;
}

#StopFollowingAction {
	float: right;
}
