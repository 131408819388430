﻿// Brand colors and common colors.

/* Brand Colors (will be overwritten if a brand has defined colors) */
body {
	--brandViewerButton: var(--infinityyLilac);
	--brandViewerButtonText: white;
	--brandViewerButtonHover: var(--infinityyLilac);
	--brandViewerButtonHoverText: white;
	--brandViewerButtonOff: var(--infinityyLilacDarker);
	--brandViewerButtonOffText: var(--infinityySecondaryText);
	--brandCTAButton: var(--infinityyYellow);
	--brandCTAButtonText: white;
	--brandCTAButtonHover: var(--infinityyYellow);
	--brandCTAButtonHoverText: var(--infinityyNavy);
	--brandHeaderColor: var(--infinityyNavy);
	--brandHeaderPrimaryText: var(--infinityyPrimaryText);
	--brandHeaderSecondaryText: var(--infinityySecondaryText);
	--brandMenuColor: var(--infinityyDarkGreyLighter);
	--brandMenuPrimaryText: var(--infinityyPrimaryText);
	--brandMenuSecondaryText: var(--infinityySecondaryText);
	--brandContentColor: var(--infinityyDarkGrey);
	--brandContentPrimaryText: var(--infinityyPrimaryText);
	--brandContentSecondaryText: var(--infinityySecondaryText);
}

/* brand styles to go along with variables */

.brandViewerButtonColors {
	background-color: var(--brandViewerButton);
	color: var(--brandViewerButtonText);

	&:hover {
		background-color: var(--brandViewerButtonHover);
		color: var(--brandViewerButtonHoverText);
	}

	&.elementHidden {
		background-color: var(--brandViewerButtonOff);
		color: var(--brandViewerButtonOffText);
	}
}

.brandCTAButtonColors {
	background-color: var(--brandCTAButton);
	color: var(--brandCTAButtonText);

	&:hover {
		background-color: var(--brandCTAButtonHover);
		color: var(--brandCTAButtonHoverText);
	}
}

.brandHeaderColors {
	background-color: var(--brandHeaderColor);
	color: var(--brandHeaderPrimaryText);

	& .secondaryColor {
		color: var(--brandHeaderSecondaryText);
	}
}

.brandMenuColors {
	background-color: var(--brandMenuColor);
	color: var(--brandMenuPrimaryText);

	& .secondaryColor {
		color: var(--brandMenuSecondaryText);
	}
}

.brandContentColors {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);

	& .secondaryColor {
		color: var(--brandContentSecondaryText);
	}
}

.infinityyCTAColor {
	background-color: var(--infinityyYellow);
	box-shadow: 0 0 0 rgba(0,0,0,0);
	color: white;
	transition: all linear .2s;
}
