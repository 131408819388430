#FloorPlanContainer {
	border-radius: 0;
	margin: 0;
	bottom: 0;
	position: relative;
	display: block;
	flex: 1 1 auto;
	width: 100%;
	height: 100%;
}

.floor-plan-notfound {
	align-items: center;
	background-color: transparent;
	color: white;
	display: flex;
	flex: 1 1 auto;
	font-weight: 400;
	font-size: 14px;
	height: 100%;
	justify-content: center;
	width: 100%;
}

.floor-plan-loading {
	background-color: transparent;
}

.everyscape-floor-plan-content-clients {
	background-position: center;
	background-size: contain;
	height: 40px;
	left: -15px;
	position: absolute;
	top: -15px;
	width: 40px;
}

.everyscape-floor-plan-client {
	background-image: url('../images/floor-plan-nodes/floor_plan_other.svg');
	background-size: contain;
	border-radius: 50%;
	color: #ffffff;
	font-size: 1.2em;
	font-weight: 700;
	left: -10px;
	pointer-events: none;
	position: absolute;
	top: -10px;
	height: 60px;
	text-align: center;
	vertical-align: top;
	width: 60px;
	z-index: 100;

	&.everyscape-floor-plan-self-client {
		background-image: url('../images/floor-plan-nodes/floor_plan_you.svg');
		color: #000000;
		left: -10px;
		height: 60px;
		position: relative;
		top: -10px;
		width: 60px;
		z-index: 200;
	}
}

div.everyscape-floor-plan-content {
	height: 20px;
	width: 20px;

	&:hover {
		cursor: pointer;
	}

	&.display-icon-default {
		& .everyscape-floor-plan-content-clients {
			background-image: url('../images/floor-plan-nodes/floor_plan_node_2d.svg');
		}

		&:hover .everyscape-floor-plan-content-clients {
			background-image: url('../images/floor-plan-nodes/floor_plan_node_hover_2d.svg');
		}

		&.panoramic-content {
			& .everyscape-floor-plan-content-clients {
				background-image: url('../images/floor-plan-nodes/floor_plan_node_pano.svg');
			}

			&:hover .everyscape-floor-plan-content-clients {
				background-image: url('../images/floor-plan-nodes/floor_plan_node_hover_pano.svg');
			}
		}
	}

	&.display-icon-none {
		& .everyscape-floor-plan-content-clients {
			background-image: url('../images/floor-plan-nodes/floor_plan_node_small.svg');
		}

		&:hover .everyscape-floor-plan-content-clients {
			background-image: url('../images/floor-plan-nodes/floor_plan_node_small_hover.svg');
		}
	}
}

.everyscape-floor-plan-content-overlay {
	display: none;
	position: absolute;
	z-index: 1000;

	&.everyscape-floor-plan-content-overlay-show {
		background-color: #ce2127;
		border: 0px solid #333;
		border-radius: 50%;
		color: #fff;
		display: none;
		font-size: .6em;
		font-weight: 700;
		height: 24px;
		left: -10px;
		line-height: 22px;
		text-align: center;
		top: -10px;
		vertical-align: middle;
		width: 24px;

		&.everyscape-floor-plan-content-overlay-self {
			background-color: #66a619;
			height: 32px;
			left: -12px;
			line-height: 30px;
			top: -12px;
			width: 32px;
		}
	}
}
