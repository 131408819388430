﻿@use 'mixins.scss' as m;

.conversation-group {
	border: 2px solid var(--brandContentColor);
}

/************ AI Interaction **************/
#AutoTourControl {
	background-color: m.$infinityy-teal;
}

#AiChatHistory {
	background-color: var(--brandMenuColor);
	color: var(--brandMenuPrimaryText);
}

#AiInteractionContainer {
	background-color: var(--brandContentColor);

	button {
		background-color: var(--brandMenuColor);
		color: var(--brandMenuPrimaryText);
		@include m.buttonRing(var(--brandCTAButton));

		&#AiTtsMute {
			color: var(--brandMenuPrimaryText);
			background-color: var(--brandMenuColor);
			@include m.buttonRing(var(--brandMenuColor));

			.disabled {
				opacity: 0.3;
			}
		}

		&#AiExplore, &#NonAiExplore {
			margin-left: 0.5rem;
		}

		&#AiInputSubmit {
			margin-right: 0.5rem;

			&.pulsing {
				animation: pulsing 1.5s infinite;

				svg {
					animation: svgColorPulse 1.5s infinite;
				}
			}
		}
	}
}

#AiInput {
	background-color: var(--brandMenuColor);
	border-color: var(--brandMenuColor);
	color: var(--brandMenuPrimaryText);
	height: 34px;
	min-width: 0;

	&:hover, &:focus {
		border-color: var(--brandCTAButton);
	}

	&::placeholder {
		color: var(--brandMenuSecondaryText);
	}
}

@keyframes pulsing {
	0% {
		box-shadow: 0 0 0 2px rgba(144, 112, 212, 1);
	}

	50% {
		box-shadow: 0 0 5px 2px rgba(144, 112, 212, 1);
	}

	99% {
		box-shadow: 0 0 5px 2px rgba(144, 112, 212, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(144, 112, 212, 0);
	}
}

@keyframes svgColorPulse {
	0% {
		fill: #ffffff;
	}

	25% {
		fill: #9070d4;
	}

	50% {
		fill: #ffffff;
	}
}
