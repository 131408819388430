﻿.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.modal {
	--modalBG: var(--brandMenuColor);
	--modalButtonColor: var(--infinityyYellow);
	--modalButtonHoverColor: var(--infinityyYellow);
	--modalButtonHoverText: var(--infinityyNavy);
	--modalButtonText: white;
	--modalHeadlineText: var(--brandMenuSecondaryText);
	--modalInputBG: var(--brandContentColor);
	--modalText: var(--brandMenuPrimaryText);
	--modalTextLinkColor: var(--infinityyYellow);
	--modalTextLinkHoverColor: var(--infinityyNavy);
	--modalSubtitleText: var(--brandContentSecondaryText);
	-webkit-overflow-scrolling: touch;
	color: var(--brandContentPrimaryText);
	display: none;
	background-image: url(../images/Infinityy-ConnectionHeader-brighter.png);
	background-position: center top;
	background-repeat: no-repeat;
	background-color: var(--brandContentColor);
	background-size: cover;
	bottom: 0;
	left: 0;
	outline: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1050;
}

.modal.in .modal-dialog {
	-ms-transform: translate(0,0);
	-o-transform: translate(0,0);
	-webkit-transform: translate(0,0);
	transform: translate(0,0);
}

.narrowModal {
	& .modal-dialog {
		max-width: 100%;
		width: 350px;
	}
}

.mediumModal {
	& .modal-dialog {
		max-width: 450px;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.modal-dialog {
	margin: 100px auto 200px auto;
	position: relative;
	width: auto;
}

.modal-header {
	border-bottom: 1px solid #e5e5e5;
	border-width: 0;
	padding: 15px;

	& .close {
		color: var(--brandMenuSecondaryText);
		opacity: 0.8;
		margin-top: -2px;
	}
}

.modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before {
	display: table;
	content: " ";
}

.modal-content {
	background-clip: padding-box;
	background-color: var(--brandMenuColor);
	border-radius: 20px;
	border: 1px solid var(--brandMenuColor);
	box-shadow: none;
	color: var(--brandMenuPrimaryText);
	outline: 0;
	position: relative;

	.infinityyInput, .cancelButtonColors {
		background-color: var(--brandContentColor);
		color: var(--brandContentPrimaryText);

		&::placeholder {
			font-style: italic;
			color: var(--brandContentSecondaryText);
		}
	}
}

.modalContent{
	padding:0 20px;
}

.modalTitle {
	background-color: var(--brandMenuColor);
	color: var(--brandMenuPrimaryText);
	padding-bottom: 10px;
	padding-top: 10px;
	text-align: center;
	word-break: break-word;
}

.modalSubtitle {
	background-color: var(--brandMenuColor);
	color: var(--brandMenuSecondaryText);
	line-height: 24px;
	padding-bottom: 10px;
	text-align: center;
}

.modalBody {
	flex: 1 1 auto;
	padding: 20px;
}

.modalButtonHolder {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}

.modalButton {
	border-radius: 50px;
	border-width: 0;
	cursor: pointer;
	line-height: 24px;
	min-width: 100px;
	margin-right: 10px;
	padding: 10px;
	text-align: center;
	white-space: nowrap;
	flex:1 1 auto;

	&.stretch {
		flex: 0 1 150px;
	}

	&:last-child {
		margin-right: 0;
	}

	&.lilacButton {
		background-color: var(--infinityyLilac);
		color: white;
	}

	&.lighterNavyButton {
		background-color: #353C6C;
		color: white;
	}
}

.modalInputAndLabel {
	padding-top: 20px;
}

.modalList li {
	margin-bottom: 10px;
}

.form-submit-button-disabled {
	opacity: 60%;
}

// Specific modals.

// SelfIdentifyModal
#SelfUsername {
	text-align: center;
}

// LoginModal
#ModalLoginContainer {
	width: 100%;
}

#ModalLoginLoginArea {
	padding: 15px;
}

#ModalExplanationContainer {
	width: 100%;
}

#ModalExplanationMainArea {
	padding: 15px;
}

#InviteShowingUsersModal {
	& .modalButton {
		margin-bottom: 0;
	}

	& textarea {
		margin-bottom: 20px;
	}
}

#InviteTabs {
	margin-bottom: 30px;
}

.threeTabs {
	background-color: var(--brandContentColor);
	border-radius: 50px;
	box-shadow: 0 1px 0 rgba(0,0,0,.2) inset;
	color: var(--brandContentSecondaryText);
	display: grid;
	grid-gap: 5px;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 2px;
	margin-bottom: 20px;
}

.createContentSectionTab {
	display: inline-block;
	cursor: pointer;
	line-height: 30px;
	text-align: center;
	white-space: nowrap;
	padding: 10px 20px 10px 20px;
	border-radius: 50px;
}

.createContentSectionTab.selected {
	color: var(--brandCTAButton);
	box-shadow: 0 0 5px rgba(0,0,0,0.2);
	background-color: var(--brandMenuColor);
}
#DialogAskChatRow {
	padding: 0 20px 20px 20px;
}
#ContactUsButtons {
	padding: 20px;
	text-align: center;
}
.centered {
	text-align: center;
}
#ContactUsButtons > button:not(:last-child) {
	margin-bottom: 20px;
}

#ContactUsContent {
	margin:20px;
}

#SelectedPipMenuHolder {
	height: 100%;
	min-height: 170px;
	padding: 10px;
	width: 100vw;
	z-index: 120;
}

#SelectedPipMenu {
	background-color: var(--brandMenuColor);
	border-radius: 20px;
	color: var(--brandMenuPrimaryText);
	padding: 10px 0;
}

#SelectedPipCard {
	display: flex;
	justify-content: space-between;
	padding: 10px;
}

#SelectedPipCardLeftSide {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 0;
	overflow: hidden;
	flex: 1 1 auto;
}

#SelectedPipAvatar {
	border-radius: 50%;
	background-color: #c3c3c3;
	width: 40px;
	height: 40px;
	flex-shrink: 0;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

#SelectedPipNoAvatar {
	color: #676767;
}

#SelectedPipName {
	margin-left: 10px;
	margin-right: 5px;
	min-width: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.modalSection {
	background-color: var(--brandMenuColor);
	border-radius: 20px;
	box-shadow: 0 0 5px rgba(0,0,0,0.2);
	color: var(--brandMenuPrimaryText);
	margin: 0 10px 20px 10px;
	padding: 10px;
}

#SelectedPipFollowButton {
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	margin-left: 10px;
	margin-right:10px;
	padding: 10px 15px;
	color: var(--brandViewerButtonText);
	background-color: var(--brandViewerButton);
	border-width: 0;
	pointer-events: auto;
}

#SelectedPipMenu .lastButtonHolder {
	padding-left: 10px;
	padding-right: 10px;
}

.ui-dialog-titlebar { display: none; }

.follow-modal {
	bottom: 0;
	left: 0;
	right: 0;
	top: calc(Min(25vw, 96px) + 63px);
}


/* section in drawer to request info/docs/access */
.request-access-button-section {
	padding-bottom: 10px;
	padding-top: 10px;
}

.request-access-button-wrapper2 {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
}

.request-access-button-wrapper {
	background-color: var(--brandCTAButton);
	border-radius: 25px;
	color: var(--brandCTAButtonText);
	display: table;
	padding: 10px 10px 10px 20px;
	width: 100%;

	& .request-access-button-text {
		display: table-cell;
		margin: 0;
		padding-right: 4px;
		vertical-align: middle;
		width: 100%;
	}

	& .request-access-button {
		border: 1px solid rgba(0,0,0,0);
		height: 43px;
		margin: auto;
		vertical-align: middle;

		&:hover {
			border: 1px solid var(--brandMenuPrimaryText);
		}
	}
}

/* lead capture dialogs */
.lead-capture-dialog {
	color: #333;
	font: "Arial", sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin-left: 10px;
	margin-right: 10px;

	& .close {
		color: #000;
		font-size: 32px;
		position: absolute;
		right: 12px;
		top: 8px;
	}

	& h4 {
		color: #444746;
		font-size: 28px;
	}

	& input {
		border: solid 1px;
		border-radius: 5px;
		color: #7b7b7b;
		font-size: 14px;
		height: 49px;
		margin: 6px 0px;
		outline-color: #999;
		padding: 4px 8px;
		width: 273px;
	}

	& .xs {
		font-size: 10px;
	}

	& .cancel {
		background-color: transparent;
		border: none;
		color: #1577d1;
		float: left;
		font-size: 16px;
		line-height: 55px;
		margin-right: 16px;
	}

	& .submit {
		background-color: var(--brandCTAButton);
		border: none;
		border-radius: 38px;
		color: var(--brandCTAButtonText);
		float: left;
		font-size: 16px;
		padding: 16px;

		&:disabled {
			cursor: not-allowed;
			opacity: 0.8;
		}

		&:hover {
			background-color: var(--brandCTAButtonHover);
			color: var(--brandCTAButtonHoverText);
		}
	}
}

.lead-capture-dialog .button-section {
	bottom: 16px;
	position: absolute;
	right: 16px;
}

.request-access-dialog, .chat-lead-capture-dialog {
	background: #fff !important;
	border-radius: 6px;
	position: absolute !important;
	top: 100px;
	z-index: 1080;

	& .ui-widget-header {
		display: none !important;
	}
}

#ContactUsContent .request-access-button-section {
	margin-left: 20px;
	margin-right: 20px;
}

.lead-capture-dialog .left-section {
	margin-top: 20px;
	padding-left: 10px;
	padding-right: 10px;
}

.lead-capture-dialog .right-section {
	color: #444746;
	font-size: 16px;
	margin-bottom: 80px;
	margin-top: 40px;
	padding-left: 10px;
	padding-right: 10px;

	& h1 {
		font-size: 14px;
		font-weight: 700;
	}
}
