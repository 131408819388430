﻿#PipsContainer {
	align-items: stretch;
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	display: grid;
	flex: 1 1 auto;
	flex-direction: row;
	grid-template-areas: "youPip pips";
	grid-template-columns: auto 1fr;
	grid-template-rows: auto;

	justify-content: stretch;
	min-height: 0;
	overflow: hidden;
	position: relative;
	width: auto;
	z-index: 10;
}

#YouPipContainer {
	background-color: transparent;
	border: 0;
	grid-area: youPip;
	left: 0;
	line-height:0;
	pointer-events: auto;
	position: sticky;
	z-index: 15;
	& video {
		transform: scaleX(-1);
	}
}

#PipsAndInvite {
	display: flex;
	height: 100%;
	left: 0;
	overflow-x: overlay;
	overflow-y: hidden;
	pointer-events: auto;
	position: sticky;
	z-index: 5;
}

#InnerPipsContainer {
	border-radius: 0 20px 0 0;
	display: flex;
	flex-direction: row;
	pointer-events: auto;
	position: relative;
	height: 100%;
	width: 100%;
	min-height: Min(25vw, 96px);
	min-width: Min(25vw, 96px);
	z-index: 5;
}

#InnerPipsContainer canvas {
	position:absolute;
	top: 0px;
	left: 0px;
	z-index: 10;
}

.pip {
	background-color: transparent;
	border: 2px solid transparent;
	display: inline-block;
	flex: 0 0 auto;
	height: Min(25vw, 96px);
	pointer-events: auto;
	position: sticky;
	width: Min(25vw, 96px);
	z-index: 15;
	&.backgrounded {
		z-index: 5;
	}
}

.espip-avatar-image {
	background-image: url(../images/User-Profile-256-2.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 12px;
	color: #fff;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	white-space: nowrap;
	width: 100%;
	z-index: 10;
}
.espip-video-track {
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 20;

	& video {
		border-radius: 0;
		bottom: 0;
		height: 100%;
		left: 0;
		object-fit: cover;
		pointer-events: none; // Disable play/pause button on video.
		position: relative;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 10;
	}
}
.espip-text-label {
	background-color: transparent;
	border-radius: 2px;
	color: white;
	font-size: 10px;
	line-height:14px;
	overflow: hidden;
	padding: 5px 2px;
	pointer-events: none;
	position: absolute;
	text-align: center;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	width: 100%;
	z-index: 15;

	& .material-icons {
		color: rgb(33,157,144);
		font-size: 1.2em;
		margin-right: 0.2em;
		vertical-align: text-bottom;
	}
}

.espip-html-overlay {
	border-radius: 20px;
	height: 100%;
	pointer-events: none;
	position: absolute;
	width: 100%;
	z-index: 20;

	& .espip-track-icons {
		bottom: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		opacity: .8;
		overflow: hidden;
		position: absolute;
		text-align: center;
		width: 100%;

		& div.material-icons {
			border-radius: 50%;
			font-size: 10px;
			line-height: 1.5em;
			margin: 0.2em;
			overflow: hidden;
			height: 1.5em;
			width: 1.5em;

			&.off {
				background-color: #333;
				color: #fff;
			}
		}
	}
}

.dominantSpeaker {
	border: solid lime 2px !important;
}

#GuideGroupAction {
	align-items: center;
	background-color: var(--brandViewerButton);
	border-radius: 50px;
	border-width: 0;
	color: var(--brandViewerButtonText);
	display:flex;
	float: left;
	font-size: 14px;
	justify-content:center;
	margin-left: 10px;
	margin-top: 10px;
	padding: 10px 15px;
	pointer-events: auto;

	&:hover, &:focus {
		background-color: var(--brandMenuColor);
		color: var(--brandMenuPrimaryText);
	}
}

#ToggleAvatarMode {
	float: left;
}

.toggleSwitchButton {
	border-width: 0;
	padding: 0;
	background-color: var(--brandMenuColor);
	color: var(--brandMenuSecondaryText);
	display: flex;
	margin-left: 10px;
	margin-top: 10px;
	pointer-events: all;
	border-radius: 20px;
}

.toggleSwitchMode {
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	padding: 10px;
}

.toggleSwitchMode.active {
	color: var(--brandHeaderPrimaryText);
	background-color: var(--brandHeaderColor);
}

.toggleSwitchMode:not(.active) {
	display: flex;
}

.toggleSwitchMode:not(.active):hover {
	color: var(--brandContentPrimaryText);
	background-color: var(--brandContentColor);
}
