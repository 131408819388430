﻿@use 'mixins.scss' as m;

$bottom-drawer-spacing: 5px;
$building-logo-size: 60px;
$info-nav-button-height: 30px;
$info-nav-button-radius: 15px;

#BottomDrawer {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	min-width: 0;
	position: absolute;
	z-index: 110;

	& > * {
		padding-left: $bottom-drawer-spacing;
		padding-right: $bottom-drawer-spacing;
	}
}

#TopInfoSection {
	align-items: start;
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);

	& .twoItemFlex {
		align-items: flex-start;
	}

	button {
		background-color: var(--brandMenuColor);
		color: var(--brandMenuPrimaryText);
		@include m.buttonRing(var(--brandMenuColor));

		&#NextInfoButton {
			background-color: var(--brandCTAButton);
			color: var(--brandCTAButtonText);
			@include m.buttonRing(var(--brandCTAButton));
		}
	}
}

#InfoSection {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
}

#SpaceNameAndMenu {
	border-radius: 20px 20px 0 0;
	padding: 10px;
}

#ContentStripAndFloorPlanMenu {
	margin-bottom: 0;
	margin-top: 15px;
}

#FloorPlanSection {
	display: grid;
	grid-template-areas: "floorPlan";
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	height: 350px;
	position: relative;
}

#FloorPlanFullScreenButton {
	background-color: white;
	border-radius: 2px;
	border-width: 0;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	color: #676767;
	display: none;
	margin: 10px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;

	& .material-icons {
		font-size: 22px;
	}
}

#FloorPlanHolder {
	background-color: var(--brandMenuColor);
	border-radius: 0 0 20px 20px;
	color: var(--brandMenuPrimaryText);
	display: flex;
	grid-area: floorPlan;
	overflow: hidden;
	z-index: 1;
}

#ContentStrip {
	margin-bottom: 20px;
}

#BottomDrawerCloseBtn {
	border: 1px solid var(--brandMenuPrimaryText);
	border-radius: 50%;
	margin: 5px;
	padding: 15px;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
}

.contentItemsList {
	border-radius: 0 0 20px 20px;
	display: block;
	padding: 10px;
	text-align: left;

	& .item-cell {
		border: 1px solid transparent;
		border-radius: 10px;
		display: block;
		font-size: 14px;
		margin-bottom: 5px;
		padding: 10px;
		position: relative;

		&:hover, &.current-item {
			border: 1px solid var(--brandMenuPrimaryText);
		}
	}
}

#UDGItemsHeader {
	padding: 20px 20px 0 20px;
}

#BottomDrawerMapHolder {
	z-index: 1;
}

#SpaceInfoSection {
	border-radius: 0 0 20px 20px;
	padding: 10px;
}

#BuildingMenuBtn, #CloseBuildingSelector {
	background-color: var(--brandMenuColor);
	border-radius: 50px;
	border-width: 0;
	color: var(--brandMenuPrimaryText);
	margin-bottom: 10px;
	padding:10px 10px 10px 20px;
	width: 100%;
}
#BuildingMenuBtn {
	margin-top: 10px;
}

#CloseBuildingSelector {
	margin-top: 0;
}

#BuildingMenuLeftSide {
	flex: 1 1 auto;
}

#ResInfoSection {
	margin: 10px;
}

#ResInfoSection > div {
	margin-bottom: 10px;
}

#BuildingNameAndAddress {
	text-align: left;
}

#BuildingLogo {
	background-position: center right;
	background-repeat: no-repeat;
	background-size: contain;
	height: 50px;
	width: 100px;
}

#BuildingPhoto, #ListingPhoto {
	background-position: center center;
	background-size: cover;
	border-radius: 20px;
	height: 140px;
	margin-bottom: 15px;
	margin-top: 15px;
}

#GroupsHolder > * {
	margin-bottom: 15px;
}

#SurveyBody {
	margin: auto;
	margin-top: 0;
	max-width: 100%;
	position: relative;
	width: 100%;
}

#SurveyListingsHolder {
	margin: auto;
	width: 100%;
}

#FooterContactUsButton {
	border-radius: 50px;
	border-width: 0;
	font-size: 14px;
	padding: 10px 20px;
	margin-bottom: 40px;
	margin-top: 10px;
}

.bottomDrawerTabMenu {
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	margin-bottom: 15px;
	overflow-x: auto;
}

.bottomDrawerTab {
	border: 1px solid transparent;
	border-radius: 50px;
	cursor: pointer;
	flex: 1 1 auto;
	font-size: 13px;
	padding: 10px;
	text-align: center;
	white-space: nowrap;

	&.selected {
		border-color: var(--brandMenuPrimaryText);
	}

	&:not(:last-child) {
		margin-right: 10px;
	}
}

.buildingTabSections {
	margin-bottom: 15px;
}

.availabilitiesHeader {
	display: none;
}

.mobileListingInfo {
	display: block;
	padding:5px;

	& > *:not(:last-child) {
		margin-bottom: 5px;
	}
}

.spaceFeatures {
	padding:5px;
}

.buildingTabMenu {
	margin-bottom: 10px;
}

#ResLogo {
	display: none;
}

#UDGItemsHolder {
	border-radius: 20px;
	grid-area: udgItems;
}

.buildingAvailabilities {
	& .listingFirstLine {
		justify-content: space-between;
	}

	& .partiallyHiddenSection .listingMoreInfoSection {
		align-content: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-left: 70px;
		padding: 0 10px 20px 10px;
		text-align: left;
	}

	& .nameAndInfo {
		align-items: flex-start;
		display: flex;
		flex: 1 1 auto;
		flex-direction: column;
		justify-content: space-between;
		min-width: 0;
	}

	& .listingCellFloor {
		display: inline;
	}

	& .listingCellName {
		display: inline;
		flex-shrink: 1;
		min-width: 0;
		white-space: normal;
		word-break: break-word;
	}
}

.buildingAmenities {
	& .listingFirstLine {
		justify-content: flex-start;
		padding: 0;
		width: 100%;
	}

	& .listingCell .partiallyHiddenSection {
		min-width: 0;
		padding-left: 0;
		width: 100%;
	}

	& .partiallyHiddenSection.sectionIsSmall {
		display: none;
	}

	& .listingMoreInfoSection {
		display: none;
	}

	& .showMoreDiv {
		margin-top: 10px;
		padding: 0;
	}

	& .listingCellImage {
		align-items: center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		position: relative;
	}
}

.listingCell {
	max-width: 100%;
	width: 100%;
}

.listingCell.currentListing, .placeItem.currentListing .placeTile {
	border: 1px solid var(--brandMenuPrimaryText);
}

.amenitiesAndUDGs {
	margin-bottom: 15px;
}

.infinityyGroup.viewerSection {
	background-color: var(--brandMenuColor);
	border-radius: 20px;
	color: var(--brandMenuPrimaryText);
	padding: 20px;
}
.featureDiv:not(:last-child) {
	margin-bottom: 20px;
}

.featureLabel {
	display: block;
	font-size: 14px;
	font-weight: 700;
}

.buildingCard {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	height: 220px;
	overflow: hidden;
	width: 100%;

	& .titleArea {
		background-color: rgba(0,0,0,0.5);
		color: white;
		padding: 10px;
	}

	& .buildingLogo {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		display: none;
		height: $building-logo-size;
		width: $building-logo-size;
	}
}

.viewerSection {
	margin: auto;
	max-width: 100%;
	width: 100%;
}

#OverviewMapContainer {
	width: 100%;
}

.currentItemPhoto {
	background-position: center center;
	background-size: cover;
	border-radius: 20px;
	height: 140px;
	margin-bottom: 10px;
}

.mobileListingList .listingCell {
	background-color: var(--brandMenuColor);
	border-radius: 10px;
	color: var(--brandMenuPrimaryText);
	cursor: pointer;
	margin-bottom: 10px;
	padding: 10px;
}

.buildingsSectionTitleBar {
	padding-bottom: 20px;
}

.placesGrid {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	justify-content: space-between;
}

.placeItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 125px;
	background-color: transparent;
	border-width: 0;
	position: relative;
}

.placesGrid > * {
	margin-bottom: 20px;
}

.placeTile {
	border-radius: 20px;
	width: 125px;
	height: 125px;
	background-size: contain;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.placeTile:not(.editColors) {
	background-color: #111111;
}

.placeName {
	margin-top: 8px;
	text-align: center;
	cursor: default;
	max-width: 100%;
}

#BuildingSelector {
	padding: 0 15px 15px 15px;
}

#BuildingSelector {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
}

.buildingCard {
	width: 100%;
	height: 220px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
}

.buildingCard .titleArea {
	padding: 10px;
	background-color: rgba(0,0,0,0.5);
	color: white;
}

#BuildingSelector > *:not(:last-child) {
	margin-bottom: 15px;
}

.mapHeaderButton {
	background-color: var(--brandHeaderColor);
	border-radius: 50%;
	border-width: 0;
	color: var(--brandHeaderPrimaryText);
	display: inline-block;
	padding: 0;
	pointer-events: auto;
	z-index: 15;
}

.mapHeaderButton .flexButtonStructure {
	padding: 8px;
}

.mapHeaderButton .material-icons {
	font-size: 18px;
}

.noFloorPlan{
	display:none;
}

#AdditionalMCInfo {
	border-radius: 20px;
	margin-bottom: 20px;
	padding: 10px 15px;
}

#AdditionalMCInfo > * {
	margin: 10px 0;
}

.buildingNeighborhoods{
	margin-bottom:10px;
}

.buildingGroupsTabs {
	margin-bottom:10px;
}

.infoRow {
	display: block;
	padding-bottom: 20px;
}

.infoLabel {
	font-size: 12px;
	padding-bottom: 2px;
	text-transform: uppercase;
}

.infoSection {
	border-radius: 20px;
	padding: 15px;
}

.aboutBuilding {
	white-space: pre-line;
}
