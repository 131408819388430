﻿/* make very short screens fullscreen viewer */
@media (max-height: 540px) and (orientation: landscape) {
	#BottomDrawer, #MobileMenuAndPips, #MobilePipStatus, #footer, div.portrait-only, button.portrait-only {
		display: none !important;
	}

	#MainBodyContainer {
		grid-template-areas: "viewerAndBottomDrawer";
		grid-template-rows: auto;
	}

	#ViewerAndBottomDrawer {
		grid-area: viewerAndBottomDrawer;
		grid-template-rows:1fr;
	}

	#ViewersAndFloatingUI {
		grid-template-rows:auto;
	}

	#viewer-wrapper {
		bottom:unset;
		left:unset;
		position:relative;
		right:unset;
		top:unset;
	}
}
