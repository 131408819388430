﻿// Since this site is not using bootstrap and other CSS templates (as used by desktop site), include the global bits of
// CSS from those packages that are applicable to the mobile site. And also other global CSS.
* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	font-family: sans-serif;
}

body {
	// https://stackoverflow.com/questions/37808180/disable-viewport-zooming-ios-10-safari
	// Disable pinch/doubletap zoom on ios.
	touch-action: pan-x pan-y;
}

h1, h2, h3, h4, h5, h6 {
	line-height: 1.1;
}

.h4, .h5, .h6, h4, h5, h6 {
	margin-bottom: 10px;
	margin-top: 10px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: inherit;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
}

h4 {
	font-size: 18px;
}

p {
	margin: 0 0 10px;
}

ol, ul {
	margin-bottom: 10px;
	margin-top: 0;
}

button, html input[type=button], input[type=reset], input[type=submit] {
	-webkit-appearance: button;
	cursor: pointer;
}

button {
	cursor: pointer;
	overflow: visible;
}

button, input, optgroup, select, textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}

button, select {
	text-transform: none;
}

label {
	display: inline-block;
	font-weight: 700;
	margin-bottom: 5px;
	max-width: 100%;
}

.row {
	margin-left: -15px;
	margin-right: -15px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.hidden {
	display: none !important;
}

.asButton {
	cursor: pointer;
	display: block;
	border-radius: 50px;
	padding: 10px 20px;
}
