﻿#SplashLeadCaptureDialogWrapper {
	background-image: url('https://oliostorage.blob.core.windows.net/ugc-25d69ee5cc274757b9243b98e4c344dd/20230201233425126121_ba3c51a26d184cc697efe643bca8e326');
}

#SplashLeadCaptureDialog {
	background-color: #000;
	border-radius: 8px;
}

#SplashLeadCaptureDialogBody {
	background-color: #000;
	padding: 0 0 14px 0;
}

#SplashLeadCaptureHeader {
	overflow: hidden;
	padding: 5px 0;
	text-align: center;
	width: 100%;
}

#SplashLeadCaptureHeaderLogo {
	height: 56px;
	width: 218px;
}

#SplashLeadCaptureVideo {
	width: 100%;
}

#SplashLeadCaptureText {
	color: #bcbdb8;
	font-family: 'Comfortaa', Arial;
	font-size: 24px;
	font-style: italic;
	font-weight: 700;
}

#SplashLeadCaptureForm {
	overflow: hidden;
	padding: 10px 20px;
	text-align: center;
	width: 100%;
}

#SplashLeadCaptureInputFields {
	margin: 10px auto;
	max-width: 300px;
}

#SplashLeadCaptureInputFields .field-container {
	display: flex;
	justify-content: center;
	align-items: center;

	&::after {
		content: "\0000a0";
	}

	&.required::after {
		color: #ff4444;
		content: "*";
	}
}

#SplashLeadCaptureInputFields .field-container input {
	border: solid 2px #0a9;
	border-radius: 16px;
	color: var(--infinityyNavy);
	display: block;
	font-size: 11pt;
	font-style: italic;
	padding: 4px 6px;
	text-align: center;
	width: 90%;
	margin: 10px;

	&::placeholder {
		color: #0a9;
	}

	&:focus-visible {
		outline: 0;
	}

	&.invalid {
		border-color: #733;
	}
}

#SplashLeadCaptureButton {
	background-color: #0a9;
	border: 0;
	border-radius: 16px;
	color: #fff;
	padding: 12px 20px;

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}
