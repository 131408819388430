﻿/* Originally body.darkMode */
body {
	/* Background Colors */
	--backgroundColor: #222222;
	--sectionBackgroundColor: rgba(0,0,0,0.8);
	/* Text Colors */
	--primaryTextColor: white;
	--secondaryTextColor: #a1a1a1;
	--secondaryTextHoverColor: #dddddd;
	/* Filter Colors */
	--filterBackgroundColor: #676767;
	--filterBackgroundFocus: black;
	--filterPlaceHolderTextColor: #a1a1a1;
	/* Modal Colors */
	/* For the time being let's keep modals in "light" mode */
	--modalBackgroundColor: white;
	--modalPrimaryTextColor: black;
	--modalSecondaryTextColor: #676767;
	--modalBackgroundFade: rgba(0,0,0,0.8);
	--modalFilterPlaceHolderTextColor: #676767;
	/* Accent Colors */
	--primaryAccentColor: #219d90;
	--primaryAccentHoverColor: #90cec7;
	--primaryAccentColorDarker: #19766c;
	/* Button Colors */
	--sectionButtonColor: #333333;
	--sectionButtonHoverColor: #676767;
}

/* Alan's new survey colors */
body {
	--borderColor: #22282b;
	--headerBackground: #4d5c66;
	--labelBG: #c1eae5;
	--labelText: #12443E;
	--leaveCallText: #f67676;
	--thumbBG: #dbdbdb;
	--thumbColor: #56aef5;
}

/* Infinityy Colors */
body {
	--infinityyDarkGrey: #242424;
	--infinityyDarkGreyLighter: #353740;
	--infinityyEditMode: #45398e;
	--infinityyGrey: #494949;
	--infinityyLightGrey: #f5f5f5;
	--infinityyLightGreyHover: #ddd;
	--infinityyLilac: #9678D3;
	--infinityyLilacDarker: #4b3c6a;
	--infinityyNavy: #041e42;
	--infinityyNavyAlpha: rgba(4,30,66,.3);
	--infinityyNavyAlphaDarker: rgba(4,30,66,.6);
	--infinityyNavyAlphaDarkest: rgba(4,30,66,.8);
	--infinityyNavyLight: #6d859a;
	--infinityyNavyLighter: #1D2D57;
	--infinityyNavyMedium: #3b526d;
	--infinityyPrimaryText: white;
	--infinityyRed: #E03C31;
	--infinityySecondaryText: #ddd;
	--infinityyTeal: #00a499;
	--infinityyTealHover: #2bb3a3;
	--infinityyYellow: #ED9B33;
	--infinityyYellowLight: #f6cd99;
}
