﻿.infinityyInput, #Password, #ConfirmPassword, #NewPassword {
	background-color: var(--infinityyLightGrey);
	border-radius: 50px;
	border-width: 0;
	box-shadow: 0 1px 0 rgba(0,0,0,.2) inset;
	color: black;
	font-size: 18px;
	height: 48px;
	margin-bottom: 10px;
	padding-left: 20px;
	transition: box-shadow linear .2s;
	width: 100%;
}
