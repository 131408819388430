﻿#ChatCloseBtn {
	color: var(--brandMenuSecondaryText);
	opacity: 0.8;
}

#ChatCloseBtn:hover {
	opacity: 1;
}

#ChatContent {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 320px);
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	width: calc(100vw - 40px);
}

#ChatContent .modal-header {
	background-color: var(--brandMenuColor);
	color: var(--brandMenuPrimaryText);
}

#ChatContent .modalTitle {
	background-color: var(--brandMenuColo);
	color: var(--brandMenuPrimaryText);
}


/************* Chat Messages *************/
#ChatMessagesContainer {
	background-color: var(--brandContentColor);
	color: var(--brandContentPrimaryText);
	flex: 1 1 auto;
	padding: 1em;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.chat-message {
	width: 100%;
	margin: 0px;
}

.chat-message-info, .chat-message-body {
	background-color: var(--brandContentColor);
	color: var(--brandContentSecondaryText);
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.chat-message-bubble {
	background-color: var(--brandMenuColor);
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	color: var(--brandMenuPrimaryText);
	cursor: pointer;
	padding-left: 1em;
	padding-right: 1em;
	width: calc(100% - 10em);
}

.chat-message-thumb {
	background-size: cover;
	border-radius: 0.5em;
	border: 1px solid #333;
	cursor: pointer;
	flex: 0 0 auto;
	height: 4em;
	width: 4em;
}

.chat-message-bubble.you {
	background-color: #dfd;
}

.first-message {
	border-top: 1px solid #333;
	border-top-left-radius: 1em;
	border-top-right-radius: 1em;
	padding-top: 1em;
}

.last-message {
	border-bottom: 1px solid #333;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em;
	margin-bottom: 1em;
	padding-bottom: 1em;
}

.chat-message-question {
	font-weight: bold;
	font-style: italic;
}

.chat-message-option {
	background-color: var(--brandCTAButton);
	border-radius: 1em;
	color: var(--brandCTAButtonText);
	font-weight: bold;
	line-height: 2em;
	min-width: 6em;
	padding-left: 1em;
	padding-right: 1em;
	text-align: center;
}

.chat-message-option:hover {
	background-color: var(--brandCTAButtonHover);
	color: var(--brandCTAButtonHoverText);
}

/************* Chat Message Toast *************/
.toast-chat-message-bubble {
	background-color: var(--brandHeaderColor);
	border: 1px solid #333;
	border-radius: 1em;
	color: var(--brandHeaderPrimaryText);
	cursor: pointer;
	margin: 0;
	overflow: hidden;
	padding: 1em;
	text-overflow: ellipsis;
}

/************* Chat Input *************/
#ChatInputText {
	background-color: var(--brandMenuColor);
	border: 2px solid var(--brandMenuColor);
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	color: var(--brandMenuPrimaryText);
	flex-grow: 1;
	flex: 0 0 auto;
	padding: 10px;
	outline: none;

	&:hover {
		background-color: var(--brandContentColor);
		border-color: var(--brandContentSecondaryText);
		color: var(--brandContentPrimaryText);

		&::placeholder {
			color: var(--brandContentPrimaryText);
		}
	}

	&::placeholder {
		opacity: 0.8;
		color: var(--brandMenuPrimaryText);
		font-style: italic;
	}
}
