﻿$boldFontWeight: 700;
$boldFontFamily: 'Comfortaa', sans-serif;
$normalTextSize: 16px;

@mixin boldFont($size) {
	font-family: $boldFontFamily;
	font-weight: $boldFontWeight;
	font-size: $size;
}

$infinityy-navy-lighter: #1d2d57;
$infinityy-red: #e03c31;
$infinityy-teal: #00a499;

@mixin no-surroundings {
	border: 0;
	margin: 0;
	padding: 0;
}

@mixin buttonRing($outerGlowColor, $innerRingColor: var(--brandContentColor)) {
	box-shadow: 0 0 0 3px $innerRingColor inset, 0 0 2px 0.5px $outerGlowColor;
}
