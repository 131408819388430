﻿.ui-widget-overlay {
	background: #ddd !important;
	bottom: 0;
	left: 0;
	opacity: 0.7 !important;
	position: fixed !important;
	right: 0;
	top: 0;
}

.ifp-dialog {
	font-size: 14px;
	margin: 20px;
	position: absolute !important;
	width: calc(100vw - 40px) !important;
	z-index: 999 !important;

	& .ui-widget-header {
		display: none;
	}

	& .ui-dialog-content {
		color: black;
		background-color: white;
		overflow-y: scroll;
	}
}

.ifp-close-button {
	background-color: transparent;
	border: 0 !important;
	border-color: transparent !important;
	color: black;
	cursor: pointer;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 5px;
	text-align: center;
	top: 5px;
	vertical-align: middle;
	width: 30px;
}

.ifp-modal-head-section {
	align-items: center;
	background-color: rgba(242, 242, 242, 1);
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	padding: 1rem;
}

.ifp-modal-name {
	color: rgb(143,141,144);
	font-family: Marisa, sans-serif;
	font-weight: 500;
	font-size: 3rem;
	margin: 0;
}

.ifp-modal-head-section.ifp > * + * {
	margin-top: 0.5rem;
}

.ifp-bba-field.ifp, .ifp-modal-bba-field.ifp {
	border-left: 1px solid rgba(87,82,87,1);
	color: rgba(87,82,87,1);
	font-family: Oxygen,sans-serif;
	font-size: 1rem;
	line-height: 1;
	margin-right: 0;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	text-align: center;
}

.ifp-modal-bba-field.ifp:first-child {
	border-left: none;
}

.ifp.ifp-modal-img-section {
	background-image: url('https://oliostorage.blob.core.windows.net/mpmodel-8ul8v3h8ynp/thumb-022e8e38dfab499898b5849558b262b1_80_65_1');
	background-position: center center;
	background-size: cover;
	height: 140px;
	margin-bottom: 10px;
}

.ifp-modal-availability-section.ifp {
	font-family: sans-serif;
	font-size: 12px;
	line-height: 1.2;
	margin-bottom: 10px;

	& table {
		width: 100%;

		& tr {
			border-bottom: solid 1px #ddd;
		}
	}

	& td, & th {
		padding: 5px;
		text-align: center;
		vertical-align: middle;

		&:first-child {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}
	}
}

.ifp-modal-lease-now-button {
	background-color: #ed9b33;
	border: 0;
	border-radius: 12px;
	color: #fff;
	font-family: sans-serif;
	font-size: 12px;
	padding: 8px 12px;

	&:hover {
		background-color: rgb(4,30,66);
	}
}

.picker__box {
	border-radius: 5px 5px 0 0 !important;
}

.ifp-lease-now-button {
	background-color: #ed9b33;
	border-radius: 0 0 5px 5px;
	color: white;
	cursor: pointer;
	height: 50px;
	line-height: 50px;
	margin: -1px 1px 0 1px;
	text-align: center;
	vertical-align: middle;
}

.ifp-modal-disclaimer.ifp {
	color: rgba(87, 82, 87, 1);
	font-size: 12px;
	font-style: italic;
	line-height: 1.2;
	padding: 0 1.2rem;
	text-indent: -1rem;
}

.ifp-picker-heading {
	height: 50px;
	line-height: 50px;
	text-align: center;
	vertical-align: middle;
	width: 100%;
}
