﻿// Common text sizes, weights, fonts.
@use 'mixins.scss' as m;

.normalText {
	font-size: m.$normalTextSize;
}

.normalTextBold {
	font-size: m.$normalTextSize;
	font-weight: 700;
}

.labelText {
	font-size: 14px;
}

.labelTextSmaller {
	font-size: 12px;
}

.labelTextSmallest {
	font-size: 10px;
}

.titleTextBold {
	@include m.boldFont(20px);
}

.titleTextBoldSmaller {
	@include m.boldFont(18px);
}

.titleTextBoldLargest {
	@include m.boldFont(32px);
}

.headerText {
	background-color: var(--brandHeaderColor);
	color: var(--brandHeaderPrimaryText);
	margin-bottom: 10px;
	margin-top: 0;
	text-align: center;
}

.brand-cta-text {
	color: var(--brandCTAButton);
}
