@use 'site.scss';
@use 'variables.scss';
@use 'common-text.scss';
@use 'brand.scss';
@use 'common.scss';
@use 'layout.scss';
@use 'chat.scss';
@use 'header-menu.scss';
@use 'pips.scss';
@use 'viewers.scss';
@use 'footer.scss';
@use 'bottom-drawer.scss';
@use 'leaflet.css';
@use 'floor-plan.scss';
@use 'modals.scss';
@use 'call-modals.scss';
@use 'forms.scss';
@use 'landscape-mode.scss';
@use 'toastr.css';
@use 'datepicker.css';
@use 'splash-lead-capture.scss';
@use 'infinityy-floorplan-picker.scss';
@use 'ai-intro.scss';
@use 'ai-spinner.scss';
@use 'ai-chat.scss';
@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

i.lci {
	color: var(--brandCTAButton);
	cursor: pointer;
	text-decoration: underline;
}

i.ai-func[data-functionName] {
	color: var(--brandCTAButton);
	cursor: pointer;
	display: inline-block;
	margin-left: -10px;
	padding: 15px 10px;
	text-decoration: underline;
}
